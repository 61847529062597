const config = {
  baseUrl: '/',
  //~ imageUrl: "http://localhost:3008/images",
  //~ apiUrl: "http://localhost:3008/api",
  imageUrl: "https://orders.fastenerwarehouse.com.au/apis/api/uploads",
  apiUrl: "https://orders.fastenerwarehouse.com.au/apis/api",
  superAdmin:1,
  paging_size:20
}
export default config;
