import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import Pagination from './Pagination';
import Header from '../directives/header'
import Cookies from 'js-cookie'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import { getOrderslistAction, getTotalOrdersListAction, getorderStatusAction, updateOrderStatusAction, getOrdersDetailsAction,getShipmentOrderDetailsAction,deleteShipmentOrderDetailsAction } from '../Action/action';
const loginData = (!Cookies.get('loginSuccessfwMember')) ? [] : JSON.parse(Cookies.get('loginSuccessfwMember'));

const Orders = () => {

  const [OrdersList, setOrdersList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [totalRecords, settotalRecords] = useState(0);
  const [pagingspinloader, setpagingspinloader] = useState(1);
  const [spinloader, setspinloader] = useState(0);
  const [startIndex, setstartIndex] = useState(0);
  const [odrmodalopen, setodrmodalopen] = useState('0');
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [orderDetails, setorderdetails] = useState({});
  const [form, setForm] = useState({ status: '' });
  const [orderStatus, setorderStatus] = useState('')
  
  
  useEffect(() => {
    setspinloader(1);
    getTotalOrdersListAPI();
    getOrderslist();
 
  }, []);

  const SrNo = (index) => {
    return startIndex + index + 1;
  };

  const getTotalOrdersListAPI = async () => {
    let res = await getTotalOrdersListAction({ hobson_status: orderStatus });
    if (res.success) {
      settotalPages(res.data);
      settotalRecords(res.totalRecords);
      setpagingspinloader(0);
    }
    else {
      settotalPages(0);
      settotalRecords(0);
      setpagingspinloader(0);
    }
  }

  const getOrderslist = async () => {
    let res = await getOrderslistAction({ page: page, hobson_status: orderStatus });
    if (res.success) {
      let data = res.data;
      setOrdersList(data);
      setspinloader(0);
    }
    else {
      setOrdersList([]);
      setspinloader(0);
    }
  }

  const onPageChanged = async (data) => {
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    setstartIndex(offset);
    let res = await getOrderslistAction({ page: offset, hobson_status: orderStatus });
    if (res.success) {
      setOrdersList(res.data);
    }
    else {
      setOrdersList([]);
    }

  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options).replace(/ /g, '-');
  };

  const getorderStatusApi = async () => {
    try {
      const res = await getorderStatusAction();
      if (res.success) {
        setSelectedStatus(res.data);
      } else {

      }
    } catch (error) {

    }
  };

  // update order status
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((form) => ({
      ...form,
      [name]: value
    }));
  };

  const handleUpdate = async (order) => {
    let res = await updateOrderStatusAction({
      ...form, orderId: order.order_id
    })
    if (res.success) {
      setSelectedStatus(res.data)
      toast.success(res.msg)
    } else {
      toast.error(res.msg)
    }
  };

 

  const getHobsonSalesAlphabets = (value) => {
    if(value!='' && value != null){
      const regex = /^[0-9]+([A-Za-z]*)$/;
      const match = value.match(regex);
      return match ? match[1] : '';
    }
   else{
    return '';
   }
  };

  const setorderstatus = async (e) => {
    setorderStatus(e.target.value);
  };

  // search order with type
  const SearchFilter = async (e) => {
    setspinloader(1);
    setPage(1);
    await getTotalOrdersListAPI();
    await getOrderslist();
  };

  // clear order with Filter
  const ClearFilter = async (e) => {
    window.location.href = `${config.baseUrl}orders`;
  };
  const getShipmentOrderDetailsApi = async (order) => {
  
		let res = await getShipmentOrderDetailsAction({shipment_id:order?.id});
		if (res.success) {
			let data = res.data
			setorderdetails(data);
      setodrmodalopen('1');
      
		}else{
			setorderdetails({});
			
		}
	}
  const Odrclosebutton = async () => {
    setodrmodalopen('0'); 
}

const deleteShipmentOrderDetailsApi = async(order)=>{
  Swal.fire({
    title: 'Are you sure?',
          text:  "Are you sure you want to delete this order?",
          icon: 'warning',        
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText:  'Yes, Delete it!' 
      }).then(async (result) => {
          if (result.isConfirmed) {
              let res = await await deleteShipmentOrderDetailsAction({shipment_id:order?.id,order_id:order?.order_id});
              if (res.success) {                 
                  toast.success("Order deleted successfully!");
                  getTotalOrdersListAPI();
                  getOrderslist();
              }
                  else
                   toast.success("Error!");                    
              } 
         
      })
  }

  return (

    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">

        <Header />
        <Toaster />   
        <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
                <span><a className="homelink" href={`${config.baseUrl}`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Orders</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
          <div className="container-lg">
            <div className="row align-items-end">

              <div className="col-md-12">
                <div className="card mb-4">
                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-md-6 text-start">
                        <span>Order list</span>
                      </div>
                      <div className="row mb-4 mt-4 align-items-end">
                        <div className="col-lg-4 col-xl-2 col-md-6 mt-3">
                          <label className="form-label">Order Status</label>
                          <select className="form-control" id="orderStatus" name="orderStatus" onChange={setorderstatus} value={orderStatus}>
                            <option value="0">Select Status</option>
                            <option value="Backorder" >Backorder</option>
                            <option value="Complete" >Completed</option>
                            <option value="Processing" >Processing</option>
                          </select>
                        </div>
                        <div className="col-lg-4 col-xl-3 col-md-12 mt-4">
                          <button type="button" id="button-filter" className="btn btn-info" onClick={() => SearchFilter()}><i className="fa fa-search"></i> Search</button>
                          &nbsp;
                          <button type="button" id="button-filter" className="btn btn-primary" onClick={() => ClearFilter()}>Clear</button>
                        </div>
                        <div className="col-md-7 text-end">
						<a className="btn btn-primary createbtn d-none" href={`${config.baseUrl}create-order`}
                        ><i className="fa fa-plus" ></i>  Create order</a>
                      </div>
                      </div>
                      
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <thead className="table-light fw-semibold">
                          <tr className="align-middle table-heading">
                            <th>Sr.</th>
                            <th>Order ID</th>
                            <th>PO Number</th>
                            <th>Name / Email</th>
                            <th>Company</th>
                            <th>Order status</th>
                            <th>Order date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {spinloader == 0 ? (
                            OrdersList.length > 0 ?
                              OrdersList.map((order, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{SrNo(index)}</td>
                                    <td>#{order.quote_code}{getHobsonSalesAlphabets(order.hobson_sales_order)}</td>
                                    <td>{order.po_number}</td>
                                    <td>{order.firstname}&nbsp;{order.lastname}<br></br><br></br>{order.email}</td>
                                    
                                    <td>{order.company}</td>
                                    <td>{order.hobson_status == 'Backorder' ? 'Backorder' : order.hobson_status == 'Complete' ? 'Completed' : 'Processing'}</td>
                                    <td>{formatDate(order.date_added)}</td>
                                    <td id="view-order" ><button className='btn btn-primary editbtn'  onClick={()=>getShipmentOrderDetailsApi(order)}> <i class="fa-solid fa-eye"></i>  View </button>
                                    &nbsp;&nbsp;
                                    <button type="button" className="btn btn-primary deletebtn"  onClick={()=>deleteShipmentOrderDetailsApi(order)} ><i className='fa fa-trash'></i> Delete </button> 		
                                    </td>
                                  </tr>
                                )
                              })
                              :
                              (
                                <tr><td colspan="10" className="text-center">No order found.</td></tr>
                              )

                          ) :
                            (
                              <tr><td colspan="10" className="text-center"><img className="loaderImg search-loader" src={config.baseUrl + "images/loader.gif"} height={20} width={20} /></td></tr>
                            )}

                        </tbody>
                      </table>
                    </div>

                         {totalRecords > 20 ?   
                    totalPages  > 0 ?
                      <Pagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged} />
                      : ''
                    :''}
                  </div>

                </div>
              </div>


            </div>
          </div>
         
        </div>
        <Footer />

        <div id="orderItemModalBox" className={odrmodalopen === '0' ? "modal fade cart-modal mc-popup orderItemModalBox" : "orderItemModalBox mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgb(40 40 40 / 61%)', display: odrmodalopen === '0' ? 'none' : 'block' }}
                data-backdrop="false">            
					  <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
						<div className="modal-content">
						  <div className="modal-header">
							<h5 className="modal-title" id="oderheading">#{orderDetails.quote_code}{odrmodalopen == '1' ? getHobsonSalesAlphabets(orderDetails.hobson_sales_order) :null}</h5>
							<button type="button" onClick={Odrclosebutton} className="close" data-dismiss="modal" aria-label="Close">
							  <span aria-hidden="true">×</span>
							</button>
						  </div>
						  <div className="modal-body">
							 <div id="orderitemdata" className=""><div className="table-responsive">
					<table className="table" id="table">
						<thead>
							<tr class="tabletr">
								<th>Product</th>
								<th>Quantity</th>
								<th>Availability</th>								
								<th>Price</th>								
								<th>Total</th>	
                <th>Product certificates</th>							
							</tr>
						</thead>
							
					<tbody>
					{orderDetails && orderDetails.products && orderDetails.products.length > 0 ? (
					  <>
						{orderDetails.products.map((Opro) => (
						  <tr className="main-sec-table collapsed" key={Opro.order_product_id}>
							<td className="first_product">{Opro.hobson_description}<br></br> <br></br><span class="first_product-sku">{Opro.hobson_part_number}</span></td>
							<td className="prodcut_quantity">{Opro.hobson_quantity}</td>
							<td className="prodcut_quantity">{orderDetails.hobson_status}</td>							
							<td className="prodcut_quantity">{parseFloat(Opro.price).toFixed(4)}</td>							
							 <td className="item_price">
								${(Opro.hobson_quantity * Opro.price).toFixed(2)}
							  </td>		
                <td >{Opro.product_certificates && Opro.product_certificates.split(',').map((certificate, index) => (
                                  <a
                                    key={index}
                                    href={certificate.trim()} // Trim to avoid any extra spaces
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="invoice mr-5px" // Security measure for external links
                                  >
                                     <img  src="/images/invoice.png" />
                                 
                                  </a>
                                ))}</td>					
						  </tr>
						))}
					  </>
					) : null}

					</tbody>
					<tfoot>
						<tr className="valignTOP">
							<td align="left" colspan="2">
								
											<h4>Shipping details</h4>
								<p>
									{orderDetails.name}<br />{orderDetails.company}<br />{orderDetails.address_1}<br />{orderDetails.city} {orderDetails.state} {orderDetails.postcode}<br />AU	</p>
								<p><strong>Mobile: </strong>{orderDetails.phone}</p>
								<p><strong>Email: </strong>{orderDetails.email}</p>
										</td>
							<td align="right" colspan="4">
											
								<h4>Payment Details</h4>
								<p>Order subtotal: ${orderDetails.sub_total} <br />
								{orderDetails.order_discount > 0 ? (
									<>
									Credit Note Discount:  ${orderDetails.order_discount}<br />
									</>
								):null }					
								 
								 Order Shipping: ${orderDetails.shipping} <br />
								 Order GST: ${orderDetails.tax}<br />
								 Order Total: {orderDetails.total}</p>
								<p>PAYMENT METHOD : {orderDetails.payment_method=='COD' ? '30 Day EOM Credit Account' : 'PAID SECURELY USING YOUR CREDIT CARD'} <br /> 
								{orderDetails.payment_method != 'COD' ? (
									<>
									TXN ID: {orderDetails.txn_id}
									</>
									):null
								}
								</p>
								 
								 
							</td>
						</tr>
					</tfoot>
					</table>
					</div>
					</div>
						  </div>
						  <div className="modal-footer">
              <a type="button"  onClick={Odrclosebutton} className="close" data-dismiss="modal" aria-label="Close">Close</a>
						  </div>
						</div>
					  </div>
					</div>	
      </div>
      
      
      
    </>
  )

}
export default Orders;
