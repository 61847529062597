import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Link, useParams } from 'react-router-dom';
import Pagination from './Pagination';
import Header from '../directives/header'
import Footer from '../directives/footer'
import Sidebar from '../directives/sidebar'
import {getSubCatListAction, getTotalSubCatListAction, CatBlockUnBlockAction, getCategoryDetailsAction,UpdateCatAction, UpdateCatOrderAction } from '../Action/action';

const Subcategories = () => {
	const { id } = useParams();
	const [CatList, setCatList] 					= useState([]);	  
	const [page, setPage] 							= useState(1); 
	const [totalPages, settotalPages] 				= useState(0);
	const [totalRecords, settotalRecords] 			= useState(0);
	const [pagingspinloader, setpagingspinloader] 	= useState(0); 
	const [modalopen, setModalopen] 				= useState(0);	
	const [sortmodalopen, setSortModalopen] 		= useState(0);	
	const [editmodalopen, seteditmodalopen] 		= useState(0); 
	const [spinloader, setspinloader] 				= useState(0);
	const [pcatDetails, setpcatDetails] 		= useState({}); 
	const [catDetails, setcatDetails] 		= useState({
       id: '', 
       title: '', 
       description: '', 
       bronze_margin_per_cent: 0, 
       silver_margin_per_cent :0, 
       gold_margin_per_cent :0, 
       gold_plus_margin_per_cent :0, 
       platinum_margin_per_cent:0,
	   titanium_margin_per_cent:0,
    });	
    	const [sortForm, setsortForm] 		= useState({
       id: '', 
      sort_order:0, 
    });	
   
   useEffect(()=>{
	    setspinloader(1);
	    getPcatAPI();
	    setpagingspinloader(1);	   
		getSubCatListAPI();
		getTotalCatListAPI();
    },[]);
    
     const getPcatAPI = async () => {	   
	   let res = await getCategoryDetailsAction({id:id});
		if (res.success) {
			setpcatDetails(res.data);
		}
    } 
    
   const getTotalCatListAPI = async () => {
        let res = await getTotalSubCatListAction({p_cat:id});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);
            
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);		
			setpagingspinloader(0);	
		}
		
		
    }
    
  const getSubCatListAPI = async () => {
    let res = await getSubCatListAction({p_cat:id, page:page});
    if (res.success) {      
      setCatList(res.data);
       setspinloader(0);
    }
    else
	{
		setCatList([]);	
		 setspinloader(0);
	}   
  };
      
  const onPageChanged = async (data)  => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;
		let res = await getSubCatListAction({p_cat:id, page:offset});
        if (res.success) {
            setCatList(res.data);
        }
        else
        {
			setCatList({});	
		}

  }	
 
  const handleStatus = async (id, is_active) => {
        Swal.fire({
			title: 'Are you sure?',
            text: is_active === 0 ? "Are you sure you want to disable this Category?" : "Are you sure you want to enable this Category?",
            icon: 'warning',           
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, Disable it!' : 'Yes, Enable it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await await CatBlockUnBlockAction({ 'id': id, 'is_active': is_active });
                if (res.success) {
                    getSubCatListAPI();
                     if(is_active==0)
						toast.success("Category disabled successfully!");
                    else
                     toast.success("Category enabled successfully!"); 
                } else {
                   toast.error(res.msg);       
                }
            }
        })
    }  
  
  const closebutton = async () => {
        setModalopen(0);
    } 
   const closesortbutton = async () => {
        setSortModalopen(0);
    }  
  const EditCat = async (item) => {	   
	   let res = await getCategoryDetailsAction({id:item.id});
		if (res.success) {
			const details = res.data
			 setcatDetails({ id: details.id, title: details.title, description:details.description,
                        bronze_margin_per_cent: details.bronze_margin_per_cent, 
                        silver_margin_per_cent :details.silver_margin_per_cent,
                        gold_margin_per_cent:details.gold_margin_per_cent,
                        gold_plus_margin_per_cent:details.gold_plus_margin_per_cent,
                        platinum_margin_per_cent:details.platinum_margin_per_cent,
                        titanium_margin_per_cent:details.titanium_margin_per_cent});
			setModalopen(1); 
		}
    } 
 const EditSort = async (item) => {	   
	   let res = await getCategoryDetailsAction({id:item.id});
		if (res.success) {
			const details = res.data
			setsortForm({ id: details.id, title: details.title, description:details.description, sort_order: details.sort_order});
			setSortModalopen(1); 
		}
    }    
  const ShowCat = async (id) => {
	   window.location.href = `${config.baseUrl}sub-category/`+id;
    } 
    
   const handleInputChange = (e) => {
		const { name, value } = e.target;	
		if (/^\d*$/.test(value)) {		 
		 setcatDetails((old) => {
			  return { ...old, [name]: value };
			});
		}
	  };
	 const handleInputSortChange = (e) => {
		const { name, value } = e.target;	
		if (/^\d*$/.test(value)) {		 
		 setsortForm((old) => {
			  return { ...old, [name]: value };
			});
		}
	  };
	    
   const UpdateCategory = async (e) => {
		e.preventDefault()		
			let res = await UpdateCatAction(catDetails);
			if (res.success) {
				toast.success(res.msg);
				setTimeout(() => {
					window.location.href = `${config.baseUrl}sub-category/`+id;
				}, 1200);
				
			} else {
				toast.error(res.msg);
			}
		}	    
   const UpdateOrder = async (e) => {
		e.preventDefault()		
			let res = await UpdateCatOrderAction(sortForm);
			if (res.success) {
				toast.success(res.msg);	
				getSubCatListAPI();
				getTotalCatListAPI();
				setSortModalopen(0); 			
				
			} else {
				toast.error(res.msg);
			}
		}	   
  return (
    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
         <Toaster />
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}dashboard`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Categories</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row align-items-end">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">					
					<div className="row mb-4 mt-4 align-items-end">
					<div className="me-auto">
						<h4 className="page-title mb-3 pb-2">{pcatDetails?.description}({pcatDetails?.title}) Sub Category List</h4>
					 </div>
					</div>	
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead className="table-light fw-semibold">
                        <tr className="align-middle table-heading">
                          <th>Sr.</th>
                          <th>Code</th>
                          <th>Description</th>
                          <th>Bronze</th>
                          <th>Silver</th>
                          <th>Gold</th>
                          <th>Diamond</th>
                          <th>Platinum</th>
                          <th>Titanium</th>
                          <th>Status</th>
                          <th className="action" style={{ minWidth: '250px' }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                         {  spinloader==0 ? (
						CatList.length> 0 ?
							CatList.map((item, index) => (
								<>
									<tr key={index}>
										<td>{index+1}</td>
										<td><span onClick={() => { EditSort(item) }}>{item.title}</span></td>
										<td>{item.description}</td>
										<td>{item.bronze_margin_per_cent}</td>
										<td>{item.silver_margin_per_cent}</td>										
										<td>{item.gold_margin_per_cent}</td>
										<td>{item.gold_plus_margin_per_cent}</td>
										<td>{item.platinum_margin_per_cent}</td>
										<td>{item.titanium_margin_per_cent}</td>
										<td>
											<div className="form-check form-switch">
											  <input
												className="form-check-input"
												id={`flexSwitchCheckChecked_${index}`}
												type="checkbox"
												checked={item.is_active === 1}
												onChange={() => handleStatus(item.id, item.is_active === 1 ? 0 : 1)}
											  />
											</div>
										</td>
										<td>
											<button className='btn btn-primary editbtn' onClick={() => { EditCat(item) }}><i className="far fa-edit"></i></button>
											 &nbsp;	
											 {item.child_count > 0 ?(
											<button onClick={() => { ShowCat(item.id) }} className='btn btn-primary createbtn module-list' title="Sub Categories"><i className="fa fa-list"></i></button>
											):
											''}
																		  
										</td>
										
									</tr>
									</>
								)
							)
							:
							(
								<tr><td colspan="8" className="text-center">No category found.</td></tr>
							)
						):
							(
								<tr><td colspan="8" className="text-center"><img className="loaderImg search-loader" src={config.baseUrl + "images/loader.gif"} height={20} width={20} /></td></tr>
							)	}
                      
                      </tbody>
                    </table>
                     {totalPages > 0 ?										  
						 <Pagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged}   />
						 : ''
					 }	
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>
       <div className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: modalopen == '0' ? 'none' : 'block' }}  id="myModal" tabindex="-1" data-backdrop="false">
		  <div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
			  <div className="modal-header">				
				<button className="btn-close" type="button" onClick={closebutton} aria-label="Close"></button>
			  </div>
			  <div className="modal-body">
			  <div className="row">			   
				<div className="col-md-12  mb-3">
					<h4>{catDetails?.description}({catDetails?.title})</h4>						
				</div>	
			</div>	
				<div className="row">					
				 <div className="col-md-4 pb-4">
					 <div className="form-group">
						  <label className="col-form-label col-md-12">Bronze</label>
					 <input
						type="text"
						className="form-control"
						value={catDetails.bronze_margin_per_cent}
						id="bronze_margin_per_cent"
						name="bronze_margin_per_cent"
						onChange={handleInputChange}							
					  />
				  </div>			 				
				  </div>			 				
				  <div className="col-md-4 pb-4">
					  <div className="form-group">
						  <label className="col-form-label col-md-12">Sliver</label>
					 <input
						type="text"
						className="form-control"
						value={catDetails.silver_margin_per_cent}
						id="silver_margin_per_cent"
						name="silver_margin_per_cent"
						onChange={handleInputChange}							
					  />
				  </div>
				  </div>
				  <div className="col-md-4 pb-4">
					  <div className="form-group">
						  <label className="col-form-label col-md-12">Gold</label>
					 <input
						type="text"
						className="form-control"
						value={catDetails.gold_margin_per_cent}
						id="gold_margin_per_cent"
						name="gold_margin_per_cent"
						onChange={handleInputChange}												
					  />
					  </div>
				  </div>
				  <div className="col-md-4 pb-4">
					  <div className="form-group">
						  <label className="col-form-label col-md-12">Diamond</label>
					 <input
						type="text"
						className="form-control"
						value={catDetails.gold_plus_margin_per_cent}
						id="gold_plus_margin_per_cent"
						name="gold_plus_margin_per_cent"
						onChange={handleInputChange}												
					  />
					  </div>
				  </div>
				   <div className="col-md-4 pb-4">
					  <div className="form-group">
						  <label className="col-form-label col-md-12">Platinum</label>
					 <input
						type="text"
						className="form-control"
						value={catDetails.platinum_margin_per_cent}
						id="platinum_margin_per_cent"
						name="platinum_margin_per_cent"
						onChange={handleInputChange}												
					  />
					  </div>
				  </div>
				  <div className="col-md-4 pb-4">
					  <div className="form-group">
						  <label className="col-form-label col-md-12">Titanium</label>
					 <input
						type="text"
						className="form-control"
						value={catDetails.titanium_margin_per_cent}
						id="titanium_margin_per_cent"
						name="titanium_margin_per_cent"
						onChange={handleInputChange}												
					  />
					  </div>
				  </div>
				<div className="col-md-12 pt-25 text-end ">
					<button className="btn btn-primary" type="button" onClick={UpdateCategory}>Update</button>
				 </div>
			</div>		  
			  </div>
			  </div>			 
			  </div>
			</div>	
			
			
			 <div className={sortmodalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: sortmodalopen == '0' ? 'none' : 'block' }}  id="myModal" tabindex="-1" data-backdrop="false">
		  <div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
			  <div className="modal-header">				
				<button className="btn-close" type="button" onClick={closesortbutton} aria-label="Close"></button>
			  </div>
			  <div className="modal-body">
			  <div className="row">			   
				<div className="col-md-12 mb-3">
					<h4>{sortForm?.description}({sortForm?.title})</h4>					
				</div>	
			</div>	
				<div className="row">					
				 <div className="col-md-6 pb-4">
					 <div className="form-group">
						  <label className="col-form-label col-md-12">Display Order</label>
					 <input
						type="text"
						className="form-control"
						value={sortForm.sort_order}
						id="sort_order"
						name="sort_order"
						onChange={handleInputSortChange}							
					  />
				  </div>
				  </div>
				<div className="col-md-6 pt-25 mt-37">
					<button className="btn btn-primary" type="button" onClick={UpdateOrder}>Update</button>
				 </div>
			</div>	  
			  </div>
			  </div>			 
			  </div>			
			  </div>	     
      <Footer />
      </div>
    </>
  )

}
export default Subcategories;
