import React, { useEffect, useState } from 'react'
import Header from '../directives/header';
import Sidebar from '../directives/sidebar';
import Footer from '../directives/footer'
import Cookies from 'js-cookie'
import config from '../coreFIles/config';
import { getsettingsListAction, updatesettingsListsAction } from '../Action/action';
import toast, { Toaster } from 'react-hot-toast';


const Settings = () => {
    const [settingsList, setsettingsList] = useState([]);
	const [spinloader, setspinloader] = useState(0);  
    useEffect(() => {		 
        getsettingsListAPI()
    }, []);

	const handleKeyPress = (e) => {
		// Allow only numeric values (0-9) and certain control keys
		const isValidInput = /^[0-9\b]+$/.test(e.key);

		if (!isValidInput) {
		  e.preventDefault();
		}
	  }; 
	  
    const getsettingsListAPI = async () => {
        let res = await getsettingsListAction({});
        if (res.success) {
            setsettingsList(res.data);
        }
    };

    const inputHandler = (e) => {
        const { name, value } = e.target
        setsettingsList((old) => {
            return { ...old, [name]: value }
        })
    }

    const updatesettings = async (e) => {
        e.preventDefault()
		setspinloader(1)
        let res = await updatesettingsListsAction(settingsList);
        if (res.success) {
            toast.success(res.msg);
            setspinloader(0)
        } else {
            toast.error(res.msg);
            setspinloader(0)
        }
    }

 return (
    <>
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <Toaster />
        <Header />
         <div className="container-lg mb-4">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb my-0 ms-2">
              <li className="breadcrumb-item">
               <span><a className="homelink" href={`${config.baseUrl}users`} >Home</a></span>
              </li>
              <li className="breadcrumb-item active"><span>Settings</span></li>
            </ol>
          </nav>
        </div>
        <div className="body flex-grow-1 px-3">
        <div className="container-lg">
          <div className="row">
            
          <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header">
					<div className="row align-items-center">				
						<div className="col-md-6 text-start">
						 <span>Update Settings</span>
						</div>
						<div className="col-md-6 text-end">							
						</div>
					</div>
                </div>
                <div className="card-body inner-body">                
                 <form className="row g-3">
					 <div className="col-md-6">
						<label className="form-label" for="site_title">Site title</label>
						<input className='form-control' id="site_title" name="site_title" placeholder="Enter site tittle" type="text" onChange={inputHandler} value={settingsList?.site_title} />						
					  </div>
					   <div className="col-md-6">
						<label className="form-label" for="store_code">Store code</label>
						<input className='form-control' id="store_code" name="store_code" placeholder="Enter store code" type="text" onChange={inputHandler} value={settingsList?.store_code} />						
					  </div>
					   <div className="col-md-6">
						<label className="form-label" for="api_key">API key</label>
						<input className='form-control' id="api_key" name="api_key" placeholder="Enter API key" type="text" onChange={inputHandler} value={settingsList?.api_key} />						
					  </div>
					   <div className="col-md-6">
						<label className="form-label" for="api_token">API token</label>
						<input className='form-control' id="api_token" name="api_token" placeholder="Enter API token" type="text" onChange={inputHandler} value={settingsList?.api_token} />						
					  </div>
					   <div className="col-md-4">
						<label className="form-label" for="default_shipping">Default shipping</label>
						<input className='form-control' id="default_shipping" name="default_shipping" placeholder="Enter default shipping" type="text" onChange={inputHandler} value={settingsList?.default_shipping} onKeyPress={handleKeyPress} />						
					  </div>
					   <div className="col-md-4">
						<label className="form-label" for="default_threshold_shipping">Shipping threshold </label>
						<input className='form-control' id="default_threshold_shipping" name="default_threshold_shipping" placeholder="Enter shipping threshold" type="text" onChange={inputHandler} value={settingsList?.default_threshold_shipping} onKeyPress={handleKeyPress} />						
					  </div>
					   <div className="col-md-4">
						<label className="form-label" for="tax_rate">Tax rate</label>
						<input className='form-control' id="tax_rate" name="tax_rate" placeholder="Enter Tax rate" type="text" onChange={inputHandler} value={settingsList?.tax_rate}  onKeyPress={handleKeyPress}/>						
					  </div>
					   <div className="col-md-4">
						<label className="form-label" for="bronze_margin_per_cent">Bronze per cent</label>
						<input className='form-control' id="bronze_margin_per_cent" name="bronze_margin_per_cent" placeholder="Enter bronze per cent" type="text" onChange={inputHandler} value={settingsList?.bronze_margin_per_cent} onKeyPress={handleKeyPress} />
						</div>
						 <div className="col-md-4">
						<label className="form-label" for="silver_margin_per_cent">Silver per cent</label>
						<input className='form-control' id="silver_margin_per_cent" name="silver_margin_per_cent" placeholder="Enter per cent" type="text" onChange={inputHandler} value={settingsList?.silver_margin_per_cent} onKeyPress={handleKeyPress} />
						</div>
						 <div className="col-md-4">
						<label className="form-label" for="gold_margin_per_cent">Gold per cent</label>
						<input className='form-control' id="gold_margin_per_cent" name="gold_margin_per_cent" placeholder="Enter gold per cent" type="text" onChange={inputHandler} value={settingsList?.gold_margin_per_cent} onKeyPress={handleKeyPress} />
						</div>
						 <div className="col-md-4">
						<label className="form-label" for="gold_plus_margin_per_cent">Diamond</label>
						<input className='form-control' id="gold_plus_margin_per_cent" name="gold_plus_margin_per_cent" placeholder="Enter diamond per cent" type="text" onChange={inputHandler} value={settingsList?.gold_plus_margin_per_cent} onKeyPress={handleKeyPress} />
						</div>							  
						 <div className="col-md-4">
							<label className="form-label" for="platinum_margin_per_cent">Platinum</label>
							<input className='form-control' id="platinum_margin_per_cent" name="platinum_margin_per_cent" placeholder="Enter platinum per cent" type="text" onChange={inputHandler} value={settingsList?.platinum_margin_per_cent} onKeyPress={handleKeyPress} />
						</div>							  
						 <div className="col-md-4">
							<label className="form-label" for="titanium_margin_per_cent">Titanium</label>
							<input className='form-control' id="titanium_margin_per_cent" name="titanium_margin_per_cent" placeholder="Enter titanium per cent" type="text" onChange={inputHandler} value={settingsList?.titanium_margin_per_cent} onKeyPress={handleKeyPress} />
						</div>	  
					  
					  <div className="col-12 text-end">
						<button className="btn btn-primary" type="submit" onClick={updatesettings} disabled={spinloader === 1} >{spinloader === 1  ? 'Processing...' : 'Update'}</button>
					  </div>
					  					  
				  </form>                
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>    
      <Footer />
      </div>
    </>
  )

}
export default Settings;
