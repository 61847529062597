import React, { useEffect, useState } from 'react';
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import { getTieredGrouplistAction, gettemproductAction, savequotationAction } from '../Action/action';

const Addquotes = () => {
    const [quotesForm, setQuotesForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        company_name: '',
        tiered_id: '',
        quotesProducts: []
    });

    const [validationError, setValidationError] = useState({});
    const [tiered_group, settiered_group] = useState([]);
    const [searchProduct, setsearchProduct] = useState([]);

    useEffect(() => {
        getTieredGroupAPI();
    }, []);

    const handleKeyPress = (e) => {
        const isValidInput = /^[0-9\b]+$/.test(e.key);
        if (!isValidInput) {
            e.preventDefault();
        }
    };

    const inputHandler = (e) => {
        const { name, value } = e.target;
        setQuotesForm((prev) => ({ ...prev, [name]: value }));
    };

    const handleProductSelect = (row) => {
        const { description, sell_quantity_minimum,id,price_per,hobson_part } = row;
        setQuotesForm((prevForm) => ({
            ...prevForm,
            quotesProducts: [
                ...prevForm.quotesProducts,
                { description, sell_quantity_minimum,id,price_per,hobson_part}
            ]
        }));

        setsearchProduct([]);
    };

    const getTieredGroupAPI = async () => {
        try {
            const res = await getTieredGrouplistAction();
            settiered_group(res.success ? res.data : []);
        } catch (error) {
            console.error('Error fetching tiered groups:', error);
            toast.error("Failed to fetch tiered groups.");
            settiered_group([]);
        }
    };

    const searchtempproduct = async (str) => {
        if (str !== '') {
            const res = await gettemproductAction({ str });
            if (res.success) {
                setsearchProduct(res.data);
                console.log(res.data)
            } else {
                setsearchProduct([]);
            }
        } else {
            setsearchProduct([]);
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!quotesForm.first_name) errors.first_nameError = "Please enter first name.";
        if (!quotesForm.last_name) errors.last_nameError = "Please enter last name.";
        if (!quotesForm.email) errors.emailError = "Please enter email.";
        if (!quotesForm.phone) errors.phoneError = "Please enter phone number.";
        if (!quotesForm.company_name) errors.company_nameError = "Please enter company name.";
        if (!quotesForm.tiered_id) errors.tiered_idError = "Tiered group is required.";
        if (!quotesForm.quotesProducts.length) errors.quotesProductsError = 'Please select at least one product.';

        setValidationError(errors);
        return Object.keys(errors).length <= 0;
    };

    const removetemproduct = (hobson_part) => {
        setQuotesForm((prevForm) => ({
            ...prevForm,
            quotesProducts: prevForm.quotesProducts.filter(product => product.hobson_part !== hobson_part)
        }));
    };

    const submitForm = async (e) => {
        e.preventDefault();
        const isValid = validateForm();
		if (!isValid) {
		}
        else{
            let res = await savequotationAction({...quotesForm})
            if(res.success){
                toast.success(res.msg)
               setTimeout(() => {
					window.location.href = `${config.baseUrl}quotes`;
				}, 1200);
            }
            else{
                toast.error(res.msg)
            }
        }
    };

    return (
        <>
            <Sidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <Toaster />
                <Header />
                <div className="container-lg mb-4">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb my-0 ms-2">
                            <li className="breadcrumb-item">
                                <Link className="homelink" to={`${config.baseUrl}`}>Home</Link>
                            </li>
                            <li className="breadcrumb-item active"><span>Create quotes</span></li>
                        </ol>
                    </nav>
                </div>
                <div className="body flex-grow-1 px-3">
                    <div className="container-lg">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <div className="row align-items-center">
                                            <div className="col-md-6 text-start">
                                                <span>Create quotes</span>
                                            </div>
                                            <div className="col-md-6 text-end">
                                                <Link className="btn btn-primary editbtn text-white" to={`${config.baseUrl}quotes`}>Back</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body inner-body">
                                        <form className="row g-3" onSubmit={submitForm}>
                                        <div className="col-md-4">
                                                <label className="form-label" htmlFor="tiered_id">Tiered group<span className="req-star">*</span></label>
                                                <select
                                                    className={validationError.tiered_idError ? 'form-control is-invalid' : 'form-control'}
                                                    id="tiered_id"
                                                    name="tiered_id"
                                                    onChange={inputHandler}
                                                    value={quotesForm.tiered_id}
                                                >
                                                    <option value="">Select tiered group</option>
                                                    {tiered_group.map((tg) => (
                                                        <option key={tg.id} value={tg.id}>{tg.title}</option>
                                                    ))}
                                                </select>
                                                <div className="invalid-feedback">{validationError.tiered_groupError}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="first_name">First name<span className="req-star">*</span></label>
                                                <input className={validationError.first_nameError ? 'form-control is-invalid' : 'form-control'} id="first_name" name="first_name" placeholder="Enter first name" type="text" onChange={inputHandler} value={quotesForm.first_name} />
                                                <div className="invalid-feedback">{validationError.first_nameError}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="last_name">Last name<span className="req-star">*</span></label>
                                                <input className={validationError.last_nameError ? 'form-control is-invalid' : 'form-control'} id="last_name" name="last_name" placeholder="Enter last name" type="text" onChange={inputHandler} value={quotesForm.last_name} />
                                                <div className="invalid-feedback">{validationError.last_nameError}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="company_name">Company name<span className="req-star">*</span></label>
                                                <input className={validationError.company_nameError ? 'form-control is-invalid' : 'form-control'} id="company_name" name="company_name" placeholder="Enter company name" type="text" onChange={inputHandler} value={quotesForm.company_name} />
                                                <div className="invalid-feedback">{validationError.company_nameError}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="email">Email<span className="req-star">*</span></label>
                                                <input className={validationError.emailError ? 'form-control is-invalid' : 'form-control'} id="email" name="email" placeholder="Enter email address" type="email" onChange={inputHandler} value={quotesForm.email} />
                                                <div className="invalid-feedback">{validationError.emailError}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="phone">Phone<span className="req-star">*</span></label>
                                                <input className={validationError.phoneError ? 'form-control is-invalid' : 'form-control'} id="phone" name="phone" placeholder="Enter phone number" type="text" onChange={inputHandler} onKeyPress={handleKeyPress} maxLength="10" value={quotesForm.phone} />
                                                <div className="invalid-feedback">{validationError.phoneError}</div>
                                            </div>
                                            
                                           
                                            <div className="col-md-4">
                                                <label className="form-label" htmlFor="quotesProducts">Search product <span className="req-star">*</span></label>
                                                <input
                                                    className={validationError.quotesProductsError ? 'form-control is-invalid' : 'form-control'}
                                                    name="quotesProducts"
                                                    placeholder="Search product"
                                                    type="text"
                                                    onChange={(e) => searchtempproduct(e.target.value)}
                                                    autoComplete="off"
                                                />
                                                {searchProduct.length > 0 && (
                                                    <ul className="suburbList">
                                                        {searchProduct.map((row) => (
                                                            <li key={row.id} onClick={() => handleProductSelect(row)}>
                                                                {row.hobson_part}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                                <div className="invalid-feedback">{validationError.quotesProductsError}</div>
                                            </div>
                                            {quotesForm.quotesProducts.length > 0 && (
                                                <div className="row" id="tempproducts">
                                                    <table className="table mb-0">
                                                        <thead className="table-light fw-semibold">
                                                            <tr className="align-middle table-heading">
                                                                <th>PART</th>
                                                                <th>Description</th>
                                                                <th>Quantity</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {quotesForm.quotesProducts.map((row, index) => (
                                                                <tr key={index}>
                                                                    <td>{row.hobson_part}</td>
                                                                    <td>{row.description}</td>
                                                                    <td>{row.sell_quantity_minimum}</td>
                                                                    <td>
                                                                        <span className="csr-ptr" onClick={() => removetemproduct(row.hobson_part)}>x</span>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                            {quotesForm.quotesProducts.length>0?  <div className="col-12 text-end">
                                                <button className="btn btn-primary" type="submit">Create Quote</button>
                                            </div>:''}
                                           
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Addquotes;
